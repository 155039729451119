.personal-cabinet-patient-styles_text-dropdown {
  display: flex;
  margin-top: 10px;
  margin-left: auto;
  width: 90%;

  p {
    font-size: 13px;
    font-weight: bold;
    color: #404040;
    margin-bottom: 7px;

    &:nth-of-type(1) {
      min-width: 108px;
    }
  }
  select {
    background-color: #eef2f8;
    font-size: 13px !important;
    border: none;
    outline: none;
    // height: 17px !important;
    pointer-events: none;
    margin-bottom: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-top: 1px;
  }
}
.without-label {
  display: flex;
  flex-direction: column;
  select {
    background-color: #fff;
  }
  P {
    font-weight: normal;
  }
}
