.personal-cabinet-doctor-form-wrapper {
    .personal-cabinet-doctor-form_main {
        // outline: 1px solid red !important;
        display: flex;
        flex-direction: row;
        padding: 0 24px;
        padding-top: 16px;
        // ._row {
        //     width: 100%;
        //     display: flex;
        // }
        ._block1 {
            // outline: 1px solid red;
            width: 100%;
        }
        ._block2 {
            // outline: 1px solid green;
            width:  100%;
        }
    }

    .edit-mode {
        margin-bottom: 1px !important;
        display: flex;
        flex-direction: column;
        padding-top: 8px !important;

        input {
            border: 1px solid #95989a !important;
            pointer-events: all !important;
        }
        select {
            border: 1px solid #95989a !important;
            pointer-events: all !important;
            border-radius: 5px !important;
            height: 33px !important;
            padding-left: 8px !important;
        }
    }

    // outline: 1px solid red;

    .personal-cabinet-patient-styles_text-input {
        // outline: 1px solid red;
        width: 90%;
    }

    .personal-cabinet-patient-styles_text-dropdown {
        // outline: 1px solid red;
        width: 90%;
        margin-top: 0;
    }

    .one_block_row {
        display: flex;
        margin-left: 10%;

        .personal-cabinet-patient-styles_text-input {
            width: 50%;
        }
    
        .personal-cabinet-patient-styles_text-dropdown {
            width: 50%;
        }
    } 

    .personal-cabinet-patient-styles_text-input {
        width: 90%;
    }
}
