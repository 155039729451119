.header {
    max-width: 910px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 104px;

    &_lang {
        font-family: "Arial", sans-serif;
        font-size: 11px;
        font-weight: "regular";
        display: flex;
    }

    &_logo__lang {
        display: flex;
        align-items: center;
    }

    &_wrapper {
        position: relative;
        top: 0;
        width: 100%;
        background: linear-gradient(45deg, #ece86d, #9ddbe1);
    }

    &_title {
        font-family: "Arial", sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #0279bd;
        margin: 10px;
        padding-top: 10px;
    }

    &_logo {
        height: 80px;
        width: 80px;
        margin-top: 10px;

        &_container {
            display: flex;
            align-items: center
        }
    }
    .autorisation{
        padding-left: 20px;
    }
    
}