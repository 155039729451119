.services {
  margin: 35px 0px 50px 0px;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;

  li {
    display: inline;
    margin: 0;
    .services-item__disabled {
      pointer-events: none;
      color: grey;
    }
  }
}
