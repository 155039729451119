.medical_card_main {
    .content-block {
        h1 {
            margin-top: -20px;
        }
        div {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .my-in-wrapper {
        min-width: 1100px;
        .left-block {
            max-width: 250px;
            min-height: 460px;
            .item {
                div {
                    margin-left: -12px;
                    margin-right: -28px;
                    margin-top: -6px;
                    margin-bottom: -6px;
                    height: 40px;
                    width: 60px;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: center;
                }
                &:nth-of-type(1) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_medicines.png");
                    }
                }
                &:nth-of-type(2) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_surveys.png");
                    }
                }
                &:nth-of-type(3) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_allergies.png");
                    }
                }
                &:nth-of-type(4) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_vaccinations.png");
                    }
                }
                &:nth-of-type(5) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_analyzes.png");
                    }
                }
                &:nth-of-type(6) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_notes.png");
                    }
                }
                &:nth-of-type(7) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_consultations.png");
                    }
                }
                &:nth-of-type(8) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/doctor_access.png");
                    }
                }
                &:nth-of-type(9) {
                    div {
                        background-image: url("../../images/medical-card/md_items_icons/my_indicators.png");
                    }
                }
            }
        }

        .right-block {
            .no-item-patient-info-container {
                padding-left: 282px;
            }
            .user_data_block {
                display: flex;
                width: 100%;
                background-color: #dee7f5;
                padding: 2px;
                font-size: 13px;

                img {
                    width: 54px;
                    height: 54px;
                    margin-top: 2px;
                    margin-left: 4px;
                    border-radius: 7px;
                }

                div {
                    margin-left: auto;
                    margin-right: 30px;
                    margin-top: 10px;

                    p {
                        display: flex;
                        margin-bottom: 5px;
                        font-weight: bold;

                        span {
                            margin-left: auto;
                            padding-left: 40px;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

// common styles +

.medical_card_item_header {
    display: flex;
    border-bottom: 1px solid #d6d6d6;

    .md_item_title_image {
        display: flex;
        align-items: center;
        height: 62px;
        h1 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0;
            white-space: nowrap;
            padding-right: 20px;
            min-width: 208px;
        }
        div {
            height: 40px;
            width: 60px;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            margin-right: -6px;
            margin-left: 20px;
        }
    }
    .button_blck {
        margin-left: auto;
        display: flex;
        .show-more-btn {
            font-size: 13px;
            width: 110px;
            padding: 5px 25px;
            margin: auto 16px auto 0;
        }
    }
}

.medical_card_item_desc {
    border-bottom: 1px solid #d6d6d6;
    padding: 20px 0 22px 0;

    p {
        font-size: 13px;
        width: 80%;
        margin: 0 auto;
        line-height: 15px;
    }
}

.medical_card_item_data_block {
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;

    .medical_card_item_title {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
    }
    .button_blck {
        display: flex;
        margin-left: auto;
        .cancel-btn {
            margin-right: 10px;
        }
    }

    .table_data {
        width: 100%;
        margin-top: 15px;
        //max-height: 260px;
        overflow: hidden;
        //overflow-y: scroll;

        table {
            width: 100%;
            tr,
            td,
            th {
                border: 1px solid #d6d6d6;
                font-size: 11px;
                font-weight: normal;
                padding: 5px;
            }

            th {
                &:nth-of-type(1) {
                    width: 180px;
                }
            }
            .centerItem {
                text-align: center;
            }
            td {
                &:nth-of-type(1) {
                    display: flex;
                    border: none;
                    margin-left: 10px;

                    div {
                        margin-right: 10px;
                    }
                }
            }
        }
        .pointer {
            tr {
                td {
                    cursor: pointer;
                }
            }
        }
    }
}
.no-items-no-results {
    text-align: center;
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    border: 1px solid #4e9dce;
    border-radius: 7px;
    p {
        font-size: 0.9rem;
        color: #4e9dce;
        margin: 0;
    }
    margin-top: 20px;
}

// common styles -
