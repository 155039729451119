@import "../../variables.scss";
.auth-form {
  &-flex {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0;
  }
  .oneGroup {
    margin-bottom: 30px;
  }
  &__col {
    width: 288px;
  }
}
