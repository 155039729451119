@import './variables';
body {
    font-family: Arial, Helvetica, sans-serif !important;
}

.btn {
    font-size: $medium-label-font-size !important;
}

.btn-light {
    a {
        color: $blue-main;
        line-height: 20px;
    }
    a:hover {
        text-decoration: unset;
        color: $blue-main;
    }
}

.blue-btn {
    background-color: $button-blue !important;
    display: table !important;
    color: white !important;
    padding: 5px 30px 6px 30px !important;
    border-radius: 7px !important;
    border: none !important;
    &:hover {
        background-color: $button-blue-hover !important;
        cursor: pointer !important;
    }
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2) !important;
    &.dark {
        background-color: $button-dark-blue !important;
        &:hover {
            background-color: $button-dark-blue-hover !important;
        }
    }
    &.auth-button {
        height: $auth-button-height !important;
    }
    p {
        margin-bottom: 0;
        width: max-content;
    }

    &:disabled {
        pointer-events: none;
    }
}

.hide-price {
    display: none;
}

.vd-dropdown__item {
    font-size: $medium-label-font-size;
    padding: 20px 30px 60px 30px;
}

.vd-dropdown__title {
    font-size: 15px;
    height: 32px;
}

.react-autosuggest__suggestion {
    padding: 4px 12px;
    font-size: 12px;
    font-family: unset;
}

.react-autosuggest__section-title {
    font-size: $medium-label-font-size;
    font-family: unset;
}

.spinner-button {
    display: flex !important;
    flex-direction: row;
    .spinner-div {
        margin-left: -23px;
        margin-right: 4px;
    }
}

.bold {
    font-weight: bold;
}

ul.dashed {
    list-style-type: none;
    &>li {
        text-indent: -5px;
        &:before {
            content: "-";
            text-indent: -5px;
        }
    }
}

.MuiTooltip-popper {
    max-width: 160px;
}