.doctor-user-access-wrapper-main {
    h1 {
        font-size: 1.3rem;
        text-align: center;
        margin: 20px 0;
    }

    .my-md-wrapper {
        margin: 0 auto;
        max-width: 940px;
        // padding: 20px;
        border: 1px solid #d6d6d6;

        .doctor-to-patient-access-tab {
            border: 1px solid #d6d6d6;
            background-color: #fff;
            border-collapse: collapse;
            width: 95%;
            margin: 22px auto;

            tr {
                td,
                th {
                    border: 1px solid #d6d6d6;
                    font-size: 11px;
                    text-align: center;
                }

                th {
                    padding: 8px 0;
                    &:nth-of-type(3) {
                        text-align: left;
                        padding-left: 20px;
                    }
                    &:nth-of-type(4) {
                        width: 25%;
                    }
                }

                &:nth-of-type(1) {
                    th {
                        &:nth-of-type(1) {
                            width: 4.5%;
                        }
                        &:nth-of-type(2) {
                            width: 4.5%;
                        }
                    }
                }

                td {
                    cursor: pointer;
                    img {
                        border-radius: 13px;
                    }

                    &:nth-of-type(3) {
                        text-align: left;
                        padding-left: 20px;
                    }
                }
            }
        }

        .search-patient-by-name-block {
            height: 60px;
            background-color: red;
            display: flex;
            color: #26364b;

            .left {
                width: 35%;
                background-color: #eef2f8;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h6 {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 0;
                }
            }
            .right {
                width: 65%;
                background-color: #dee7f5;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .regular-text {
                    // width: 20%;
                    margin: 0;
                    text-align: center;
                }

                .form-control-sm {
                    width: 60%;
                }
            }
        }
    }
}
