.reception-standards {
  max-width: 910px;
  margin: auto;
  color: #303030;
  h2 {
    margin-bottom: 20px;
    font-weight: 700;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.125rem;
  }
  p {
    padding: 0;
    margin: 0 0 20px;
  }
  a {
    color: #46b25c;
    text-decoration: none;
  }
}
