.mobile-home{
    &_header {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &_logo {
            width: 59px;
            height: 59px;
        }
        &_text {
            margin-left: 14px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 32px;
            font-weight: bold;
            color: #0279BD;
        }
    }
    &_main {
        height: 428px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background: rgb(153,195,255);
        background: -moz-linear-gradient(315deg, rgba(153,195,255,1) 0%, rgba(208,235,255,1) 100%);
        background: -webkit-linear-gradient(315deg, rgba(153,195,255,1) 0%, rgba(208,235,255,1) 100%);
        background: linear-gradient(315deg, rgba(153,195,255,1) 0%, rgba(208,235,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99c3ff",endColorstr="#d0ebff",GradientType=1);

        &_image {
            width: calc(343px * 1078 / 1675);
            height: 343px;
            margin: 0 auto;
        }
        h5 {
            font-size: 15px !important;
            font-weight: bold;
            margin: 12px auto;
            text-align: center;
        }
        span {
            font-size: 10px;
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }
    &_container {
        padding: 0 20px;
        font-size: 10px;
        
        &_appstore {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            img {
                width: 179px;
                height: calc(179px * 173 / 548);
            }
        }
        &_playmarket {
            display: flex;
            justify-content: center;
            margin: 38px 0;
            img {
                width: 211px;
                height: calc(211px * 175 / 645)
            }
        }

        &_contacts {
            display: flex;
            margin-bottom: 25px;
            
            >div {
                width: 100px;
            }
        }
    }
    &_partners {
        background-color: #eef2f8;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 121px;

        &_sn {
            width: 90px;
        }
        &_isim {
            width: 130px;
        }
        &_undp {
            width: 48px;
        }
        img {
            margin-right: 20px;
        }
        img:last-child {
            margin-right: 0;
        }
    }
    &_footer {
        height: 75px;
        padding: 0 20px;
        display: flex;
        align-items: center;

        img {
            width: 155px;
        }
    }
}