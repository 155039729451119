.doctors_access_wrapper {
    .medical-card-title {
        font-size: 26px;
        color: #49414c;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .my-md-wrapper {
        width: 650px;
        margin: 0 auto;
        background-color: #f7f9fc;
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
        border-radius: 3px;
    }

    .my-md-item {
        width: 280px;
        height: 80px;
        margin-right: 30px;
        margin-top: 30px;
        background-color: #fbfcfd;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        padding: 12px;
        display: flex;
    }

    .my-md-item:nth-child(even) {
        margin-right: 0;
    }

    .md-item-text-block {
        width: 93%;
    }

    .md-item-time-date {
        font-size: 10px;
        color: #898989;
        margin-bottom: 0;
    }

    .md-item-title {
        margin-top: 5px;
        line-height: 14px;
    }

    .md-item-title,
    .md-item-desc {
        color: #0279bd;
        font-size: 14px;
        margin-bottom: 0;
    }

    .md-item-icons-block img {
        display: block;
        margin-top: 3px;
    }

    .md-item-icons-block img:nth-child(1) {
        width: 16px;
    }

    .md-item-icons-block img:nth-child(2) {
        width: 18px;
        margin-top: 6px;
        margin-left: -2px;
    }

    .md-item-icons-block img:nth-child(3) {
        width: 11px;
        margin-top: 7px;
    }
}
