.content {
  &-wrapper {
    max-width: 910px;
    margin: auto;
  }
}

.doctorSearch {
  display: flex;
  align-items: center;
  height: 110px;
  background-color: #eef2f8;
  justify-content: space-evenly;
  border-radius: 5px;

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 20px;
    margin-top: 20px;
  }

  &-input {
    min-width: 500px;
  }

  &-item {
    min-width: 210px;
    padding-bottom: 20px;
  }

  &-slogan {
    font-family: "Arial", sans-serif;
    font-size: 13px;
    color: #49414c;
    max-width: 270px;
  }
}
.spec {
  &-list {
    display: flex;
    justify-content: space-around;
    ul {
      padding-left: 10px;
      font-size: 14px;
    }
    li {
      color: #0279bd;
      a {
        color: #0279bd;
      }
    }
  }
}

.big-letter {
  font-size: 1.875rem !important;
  line-height: 40px;
}
.show-more-but {
  font-size: 14px;
  color: #0279bd;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    padding-left: 5px;
  }
}
.danger-block {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  border: 1px solid #ced1db;
  margin: 20px 0;
  img {
    padding: 10px 20px;
  }
  p {
    margin: 0;
  }
  &__title {
    padding: 0 15px;
  }
}

.doctor-block__disease {
  padding: 0px 15px 10px;
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 320px;
    background-color: #fff;
    margin: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    img {
      max-height: 220px;
    }
  }
  &-exp {
    margin-right: 5px;
    padding: 0 8px 0px 3px;
    display: inline-block;
    box-sizing: border-box;
    // min-width: 72px;
    height: 20px;
    line-height: 1.25rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    color: #26364b;
    background: #eef2f8;
    border-radius: 4px;
    font-size: 13px;
  }
  &-name {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 3px 0;
    // font-weight: 400;
    a {
      text-decoration: none;
      color: #26364b;
      &:hover {
        color: #0021d9;
        text-decoration: none;
      }
    }
  }
  &-exp-academic {
    display: flex;
    flex-direction: row;
    // justify-content: center;
  }
  &__spec {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #828ea5;
  }
  &-academic {
    padding: 2px 8px;
    font-size: 0.625rem;
    line-height: normal;
    white-space: nowrap;
    color: #00aeca;
    border: 1px solid rgba(0, 174, 202, 0.2);
    border-radius: 4px;
  }
}
.shield-block {
  &-prices {
    // display: none;
    opacity: 0;
    position: absolute;
    z-index: 3;
    margin-left: 3%;
    max-width: 420px;
    -webkit-animation: slideInDown 0.25s;
    animation: slideInDown 0.25s;
    font-family: SBSansInterfaceRegular, Open Sans, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-shadow: 0 6px 16px rgba(26, 61, 107, 0.16);
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    min-width: 300px;
    margin-top: -2%;

    &__price {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      line-height: 1.25rem;
      align-items: center;
    }
    &__main {
      display: flex;
      justify-content: space-between;
    }
    &__clinic {
      &-name {
        color: #00aeca !important;
        text-decoration: none;
        font-size: 0.8rem;
        line-height: 1rem;
        &:hover {
          text-decoration: none;
          color: #0021d9 !important;
        }
      }
    }
  }
  &:hover {
    .shield-block-prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 1;
      transition: 0.75s;
    }
  }
  &-info {
    margin-top: 10px;
    .shield-block-title {
      color: #26364b;
      font-size: 1rem;
      font-weight: 600;
    }
    .shield-block-context {
      margin: 4px 0px;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: #828ea5;
    }
    .shield-block-show-more {
      color: #0094e1;
      text-decoration: none;
      &:hover {
        color: #0021d9;
      }
    }
  }
}
.price-block {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 5px 5px 5px 0px;
  &-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.photos-rating-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__photo {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px #f1eded solid;
  }
  .rating-block {
    display: flex;
    position: relative;
    bottom: 15px;
    background: #fff;
    border-radius: 4px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}
.disease-doctor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.discount {
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: 400;
  padding: 5px 0px;
  color: #46b25c;
}
