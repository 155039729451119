.content {
  &-wrapper {
    max-width: 910px;
    margin: auto;
  }

  &-item {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    font-weight: "regular";
  }
}



.helpBlock {
  display: flex;

  &__title {
    font-family: "Arial", sans-serif;
    font-size: 25px;
    font-weight: bold;
    padding-top: 55px;
    max-width: 200px;
    p {
      margin: 0;
      color: #49414c;
    }
  }

  &-slogan {
    font-family: "Arial", sans-serif;
    font-size: 20px;
    font-weight: "regular";
    color: #49414c;
  }
}

.bestDoctors-wrapper {
  display: inline-flex;
  max-width: 910px;
  width: 100%;
  flex-direction: row;
  margin: auto;
  background: linear-gradient(45deg, #91dfe6, #ebfcfd);
  border-radius: 20px;
}

.bestDoctors {
  height: 240px;
  display: inline-flex;

  &-image {
    margin-top: auto;
  }

  &-block {
    padding: 40px;
  }

  &-title {
    font-family: "Arial", sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #49414c;
  }

  &-slogan {
    font-family: "Arial", sans-serif;
    font-size: 13px;
    color: #49414c;
    max-width: 270px;
    padding-top: 20px;
  }
}

.greyButton {
  background-color: #ffffff;
  font-family: "Arial", sans-serif;
  border-radius: 12px;
  font-size: 15px;
  filter: drop-shadow(0 0 0.3rem #000);
  display: inline-block;
  margin-top: 20px;
  padding: 8px 10px 8px 10px;
  text-decoration: none;
  color: #353240;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform, opacity;
  transition-property: transform, opacity;

  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    text-decoration: none;
    color: #353240;
  }

  &:hover:before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

.title {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0 20px 0;
  line-height: 22px;
  color: #49414c;

  &-center {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  &-info {
    font-family: "Arial", sans-serif;
    font-size: 15px;
    font-weight: regular;
  }
}

.services-block {
  display: flex;
  padding: 15px;
  border-radius: 20px;
  margin: 5px;

  &__title {
    font-family: "Arial", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #49414c;
  }

  &__slogan {
    color: #49414c;
    font-family: "Arial", sans-serif;
    font-size: 13px;
  }
}

.medical-service {
  display: flex;
  max-width: 910px;
}

.info-collector {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dots,
.dots:before,
.dots:after {
  width: 4px;
  height: 4px;
  background: black;
  border-radius: 50%;
}

.dots {
  position: relative;
  display: inline-block;
}

.dots:before {
  content: "";
  position: absolute;
}

.dots:after {
  content: "";
  position: absolute;
}

.post-block__chanel {
  &-image {
    border-radius: 10px;
  }
}
