.mobile-app-block {
  background: linear-gradient(90deg, #ffffff, #1b80e4);
  padding: 50px 30px 0 30px;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  max-width: 950px;
}

.app_icon {
  width: 75px;
  height: 75px;
}

.mobile-app-title {
  color: #0279bd;
  font-size: 30px;
  margin-top: 10px;
}

.mobile-app-desc {
  font-size: 16px;
  margin-top: -10px;
}

.qr-code-img {
  width: 130px;
  height: 130px;
  margin-top: 20px;
  float: left;
}

.app-images-block {
  display: flex;
  margin-top: 56px;
}

.app-store {
  margin-left: 38px;
  width: 110px;
  height: 45px;
  cursor: pointer;
}

.google-play {
  margin-left: 31px;
  width: 150px;
  height: 60px;
  cursor: pointer;
}
