.counter-block-wrapper {
  max-width: 910px;
  margin-top: 40px;
}
.new-counter-block {
  .counter-block {
    margin-top: 16px;
    padding: 39px 50px 10px 50px;
    display: flex;
    background: linear-gradient(90deg, #a7c9eb, #5ce6ee);
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    border-radius: 20px;
  }

  .counter-item {
    max-width: 180px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .counter-slogan {
    margin: auto;
    padding-top: 30px;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #353240;
  }

  .counter-title {
    font-family: "Arial", sans-serif;
    color: #0279bd;
    font-size: 29px;
    font-weight: bold;
    margin-bottom: 7px;
  }

  .counter-desc {
    text-align: center;
    font-size: 15px;
    margin-bottom: 0;
    line-height: 1;
  }
}
