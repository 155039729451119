.services-block {
  display: flex;
  padding: 20px;
  border-radius: 20px;
  margin: 5px;


  &__title {
    font-family: "Arial", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #49414c;
  }

  &__slogan {
    color: #49414c;
    font-family: "Arial", sans-serif;
    font-size: 13px;
  }
}
