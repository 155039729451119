.about-header-block {
    background: url('./../../images/about/about-header.png') no-repeat;
    box-shadow: inset 0 0 0 1000px rgba(108, 192, 226, 0.5);
    background-size: 100%;
    height: 360px;
}

.about-header-title, .about-header-desc {
    font-size: 18px;
    color: white;
    max-width: 900px;
    margin: 0 auto;
}

.about-header-title{
    padding-top: 100px;
    font-weight: bold;
}

.about-header-blue-line {
    font-size: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
    background-color: #045F94;
    padding: 20px 0;
}

/* ---------------------------------- */

.info-block-1 {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 100px auto;
}

.line-break {
    width: 100%;
 }

.info-block-1-1, .info-block-1-2, .info-block-1-3 {
    width: 48%;
    background-color: #EEF2F8;
    padding: 20px;
    border-radius: 6px;
}

.info-block-1-2{
    margin-left: 4%;
}

.info-block-1-3{
    margin-top: 2.5%;
}

.info-block-1-3 {
    width: 100%;
}

.info-block-1-1-title, .info-block-1-2-title, .info-block-1-3-title {
    font-size: 19px;
    padding-bottom: 15px;
}

.info-block-1-1-desc, .info-block-1-2-desc, .info-block-1-3-desc {
    font-size: 13px;
    line-height: 18px;
}

.info-button {
    background-color: #03A8F5 !important;
    border-color: #03A8F5 !important;
    width: 160px;
    font-size: 13px !important;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
    float: right;
    margin-right: 30px;
    margin-top: 40px;
    font-weight: bold !important;
}

.info-button:hover {
    background-color: #059ee6 !important;
}

/* ---------------------------------- */

.app-mob-wrapper {
    display: flex;
    width: 900px;
    margin: 0 auto;
}

.app-mob-1 {
    text-align: right;
    font-weight: bold;
    line-height: 16px;
}

.app-mob-3 {
    text-align: left;
    font-weight: bold;
    line-height: 16px;
}

.app-mob-p-1, .app-mob-p-3 {
    width: 300px;
    margin-top: 60px;
    color: #367aa1;
}

/* ---------------------------------- */

.info-block-2 {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 50px auto;
}

.line-break {
    width: 100%;
 }

.info-block-2-1, .info-block-2-2, .info-block-2-3, .info-block-2-4 {
    width: 48%;
    background-color: #EEF2F8;
    padding: 20px;
    border-radius: 6px;
}

.info-block-2-1, .info-block-2-4 {
    width: 100%;
}

.info-block-2-2{
    margin-right: 4%;
}

.info-block-2-2, .info-block-2-3, .info-block-2-4{
    margin-top: 2.5%;
}

.info-block-2-1-title, .info-block-2-2-title, .info-block-2-3-title, .info-block-2-4-title {
    font-size: 19px;
    padding-bottom: 15px;
}

.info-block-2-1-desc, .info-block-2-2-desc, .info-block-2-3-desc, .info-block-2-4-desc {
    font-size: 12px;
    line-height: 18px;
}

/* ---------------------------------- */

.about-slider-about {
    width: 910px;
    border-radius: 5px;
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.10);
    margin: auto;
    background: #EEF2F8;
    padding-top: 24px;
    margin-bottom: 40px;

    .carousel-control-next,
    .carousel-control-prev {
        width: 8%;
        display: flex !important;
    }
    
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23546470' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23546470' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
    .carousel-indicators li {
        background-color: #546470;
    }
}

.about-slider-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.about-slider-desc {
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    margin: 0 auto;
    min-height: 36px;
}

.about-slider-arrow-left, .about-slider-arrow-right {
    width: 0;
    height: 0;
    border-style: solid;
    display: inline-block;
    position: absolute;
    top: 45%;
}

.about-slider-arrow-left{
    border-width: 20px 25px 20px 0;
    border-color: transparent #03A8F5 transparent transparent;
    left: 1%;
}


.about-slider-arrow-right {
    border-width: 20px 0 20px 25px;
    border-color: transparent transparent transparent #03A8F5;
    margin-right: auto;
    left: 96%;
}

.about-slider-arrow-left:hover{
   cursor: pointer;    
   border-color: transparent  #0494d6 transparent transparent;
 
}
 .about-slider-arrow-right:hover {
   cursor: pointer;    
   border-color: transparent transparent transparent #0494d6;
}

.titles-desc-about {
    max-width: 1600px;
    margin: 0 auto;
}

.carousel-indicators {
    display: none !important;
}