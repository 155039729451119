.arrowBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  min-width: 500px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #26364b;
  background: #d8e2f1;
  img {
    padding-top: 2px;
    padding-left: 5px;
  }
}
.openedList {
  &__wrapper {
    display: flex;
    min-width: 300px;
    padding-bottom: 20px;
  }
}
.openedRegistList {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  .max-width200 {
    max-width: 202px;
  }
  &-title {
    padding-top: 5px;
    min-width: 144px;
  }

  textarea {
    min-width: 70%;
  }
}

.openedRegister {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}
