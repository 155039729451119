* {
  scroll-behavior: smooth;
}
.disease {
  &-blok {
    min-height: 170px;
    display: flex;
    justify-content: space-between;

    margin: 30px 0;
  }

  &-info {
    &__left {
      min-width: 300px;
      border: 1px solid rgb(196, 195, 195);
      border-right: none;
      padding: 15px;
      font-size: 15px;
      &-item {
        color: #0279bd;
      }
    }

    &__right {
      span {
        font-weight: bold;
      }
      padding: 15px;
      min-height: 170px;
      background: #eeeff3;
      border-radius: 5px;
      border: 1px solid #eeeff3;
      width: 100%;
    }
    &__block {
      // margin: 20px 0;
      h5 {
        font-weight: bold;
      }
    }
  }
}

.arrow {
  &-block {
    display: flex;
    max-height: 70px;
    justify-content: space-evenly;
    align-items: center;
    color: #000;
    background: linear-gradient(45deg, #f5ea5f, #9ddbe1);
    border-radius: 10px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;

    &:hover {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
      text-decoration: none;
      color: #353240;
    }

    &:hover:before {
      opacity: 1;
      -webkit-transform: translateY(5px);
      -ms-transform: translateY(5px);
      transform: translateY(5px);
    }
    &__title {
      margin: auto;
      font-size: 20px;
      color: #353240;
    }

    img {
      max-height: 64px;
    }
  }

  &-blok:hover {
    color: #000;
    text-decoration: none;
  }
}

.all-doctor {
  margin: 20px 0;

  span {
    font-weight: bold;
  }

  h5 {
    padding: 20px 0;
    font-weight: bold;
  }
}

.all-spec {
  display: flex;
  justify-content: center;

  &-item {
    font-size: 18px;
    font-family: Arial;
    font-weight: 600;
    color: #49414c;
    text-align: center;
    padding: 0 0 0 0;
  }
}
.scroll {
  scroll-behavior: smooth;
}
.photo-rating-block {
  display: flex;
}
.main-doctor-block {
  display: flex;
  width: fit-content;
}

.doctors-block-diseases {
  display: flex;
  max-width: 440px;
  margin: 10px 0;
  flex-direction: row;
  border-radius: 40px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  &:hover {
    text-decoration: none;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__info {
    padding: 15px;
  }
}
.doctor-diseases {
  &__rating {
    margin-top: 0;
    padding: 0;
  }
}
.photo-diseases {
  flex-direction: column;
  max-width: 190px;
  display: flex;
  &__block {
    max-width: 190px;
    display: flex;
    flex-direction: "column";
  }
  &__image-block {
    padding: 18px;
  }
}
.grey-button {
  background: #828ea5;
  color: white;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 0.875rem;
  line-height: 2rem;
  text-align: center;
  margin: 10px auto;
  display: block;
  width: 180px;
  cursor: pointer;
  &:hover {
    background-color: #a7abbb;
  }
}
