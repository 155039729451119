.footer-wrapper {
    margin-top: 35px;

    .footer-block {
        // max-width: 901px;
        display: flex;
        // margin: auto;
        //min-height: 370px;
        border-top: 1px solid rgb(196, 196, 196);
        border-bottom: 1px solid rgb(196, 196, 196);
    }

    .footer-left-wrapper {
        width: 50%;
        background-color: #eef2f8;
        display: flex;

        .footer-left {
            // outline: 1px solid red;
            margin-left: auto;
            width: 300px;
            margin-right: 150px;
            margin-top: 20px;
            margin-bottom: 20px;

            .logos-block {
                display: flex;
                margin-top: 20px;

                img {
                    margin-right: 10px;

                    &:first-of-type {
                        margin-left: -25px;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer-logo-block {
        display: flex;
        // margin: 30px 0 0 220px;
    }

    .footer-logo-img {
        width: 85px;
    }

    .footer-logo-title {
        font-size: 26px;
        font-family: Arial;
        color: #0279bd;
        margin: 25px 0 0 12px;
    }

    .footer-contacts-main {
        // margin-left: 220px;
        margin-top: 24px;
    }

    .footer-sub-contacts {
        display: flex;
        font-size: 13px;
        line-height: 8px;
        margin-bottom: 10px;
    }

    .footer-contacts-title {
        width: 120px;
        padding-top: 8px;
    }

    .footer-contacts-data p {
        margin-bottom: 0px;
        line-height: 24px;
    }

    .footer-contacts-data a {
        color: unset;
    }

    .footer-contacts-data a:hover {
        text-decoration: none;
    }

    .ezgil-img {
        width: 238px;
        // margin-left: 220px;
    }

    /* --------------- */
    /* --------------- */
    .footer-right {
        width: 50%;
        background-color: #039ce3;
        padding: 40px 0 0 20px;
        display: flex;
        position: relative;
    }

    .footer-1-column,
    .footer-2-column,
    .footer-3-column {
        width: 130px;
    }

    .footer-3-column {
        .ezgil-logo-block {
            img {
                height: 48px;
                width: 35px;
                float: left;
            }

            span {
                font-size: 10px;
                margin-bottom: 1px;
                margin-left: 2px;
                color: rgb(226, 226, 226);
            }

            top: 100%;
            right: 0;
            height: 48px;
            position: absolute;
            display: flex;
            align-items: flex-end;
            transform: translate(-30px, calc(-100% - 20px));
        }
    }

    .footer-1-column,
    .footer-2-column {
        margin-right: 25px;
    }

    .footer-right-title {
        font-size: 14px;
        color: white;
        margin-bottom: 18px;
    }

    .footer-right-list-item {
        font-size: 12px;
        line-height: 13px;
        color: white;
        margin-top: -5px;
    }

    .footer-right-list-item:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .footer-3-column-1-subcolumn {
        margin-bottom: 40px;
    }

    /* ----------------------------- */
    .main-warning {
        display: flex;
        margin: auto;
        padding: 20px 12px;
        width: 1000px;

        .main-warning-circle {
            font-size: 26px;
            border: 2px solid black;
            border-radius: 30px;
            width: 45px;
            padding-bottom: 2px;
            padding-left: 4px;
            margin-bottom: 0;
            margin-left: auto;
        }

        .main-warning-title {
            font-size: 13px;
            margin-top: 13px;
            margin-left: 15px;
            margin-bottom: 0;
        }
    }
}