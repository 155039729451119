.lineAlphabet {
  display: flex;
  justify-content: space-evenly;
  background-color: #eef2f8;
  padding: 4px;
  border-radius: 5px;
  align-items: center;
  margin: 20px 0;

  &-item {
    text-transform: uppercase;
    font-family: "Segoe UI", sans-serif;
    font-size: 20px;
    color: #000;
    cursor: pointer;
  }
  &-item:hover {
    color: #007bff;
  }
}
.selected {
  color: #007bff;
}
