@import "../../../variables.scss";
.dp-modal-title {
  margin-bottom: 0px !important;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.dp-form {
  max-width: 800px;
  .text-input-component input {
    min-width: 250px;
  }
}

.my-dp-wrapper {
  max-width: 910px;
  width: 910px;
  margin: 8px auto 16px auto;
  display: flex;
  max-height: 100%;
  height: 100%;

  .table_data {
    width: 100%;
    margin-top: 15px;
    //max-height: 260px;
    overflow: hidden;
    //overflow-y: scroll;

    table {
      width: 100%;
      tr,
      td,
      th {
        border: 1px solid #d6d6d6;
        font-size: 11px;
        font-weight: normal;
        padding: 5px;
        text-align: center;
      }

      th {
        &:nth-of-type(1) {
          width: 180px;
        }
      }

      td {
        &:nth-of-type(1) {
          display: flex;
          border: none;
          margin-left: 10px;
          div {
            margin-right: 10px;
          }
        }
      }
    }
    .pointer {
      tr {
        td {
          cursor: pointer;
        }
      }
    }
  }

  .left-block {
    min-width: 250px;
    max-width: 25%;
    flex: 1;
    background-color: #eef2f8;
    padding: 12px;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      padding: 1rem;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    .item {
      color: black;
      width: 100%;
      position: relative;
      text-decoration: none;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 12px;
      font-size: 13px;
      border-bottom: 1px solid #aaaaaa70;
      //align-items: flex-end;
      span {
        margin-left: 24px;
      }
      .arrow-left {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .item:active,
    .item:hover,
    .item-active {
      background: #cedcf1;
      .arrow-left {
        display: block;
        position: absolute;
        right: 12px;
      }
    }
  }
  .right-block {
    min-width: 300px;
    flex: 2;
    border: 1px solid #d6d6d6;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .container-header {
      border: 1px solid #d6d6d6;
      border-bottom: none;
      padding: 16px;
      .title-action {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: center;
        font-size: 14px;
        .btns {
          display: flex;
          button {
            margin-left: 16px;
          }
        }
      }
      .title-info {
        padding: 1rem;
      }
      .search-wrapper {
        margin: 15px 0 -10px 0;
        display: flex;
        justify-content: space-between;

        input {
          outline: none;
        }
      }
    }
    .container-section-1 {
      border: 1px solid #d6d6d6;
      fill: #b8b8b8;
      flex: 1;
      display: flex;
      flex-direction: column;
      .chart-wrapper {
        flex: 1;
        display: flex;
        margin: 8px;
      }
      .tabs-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .MuiTab-root {
          font-size: 13px;
          min-width: unset;
        }
        .navigation-buttons {
          align-self: center;
          font-size: 13px;
        }
        .MuiTabs-profile {
          background-color: #0000001b;
          height: 100%;
        }
      }
    }
    .apexcharts-toolbar {
      display: none;
    }
    .container-section-2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      border: 1px solid #d6d6d6;
      border-top: none;
      padding: 0 8px;
      .all-data {
        flex: 1 1 1px;
        overflow-y: scroll;
        .infinite-scroll-component {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .single-data-item {
            display: flex;
            flex-direction: row;
            padding: 4px 8px;
            align-items: center;
            font-size: 12px;
            width: 50%;
            .single-data-item-profile-data {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex: 1;
              .single-data-item-profile-data-text {
                margin-left: 16px;
              }
            }
          }
          .single-data-item {
            border-bottom: solid 1px;
            border-color: #ddd;
          }
          .single-data-item:last-child,
          .single-data-item:nth-last-child(2) {
            border-bottom: unset;
            border-color: unset;
          }
          .single-data-item:nth-child(2n + 1) {
            border-right: solid 1px;
            border-color: #ddd;
          }
        }
        .no-results {
          p {
            text-align: center;
            color: #049fe7;
          }
        }
      }
    }
    .no-item-selected {
      margin-top: 100px;
      font-size: 0.9rem;
      padding: 30px;
      border: 1px solid #4e9dce;
      border-radius: 7px;
      text-align: center;
      width: 80%;
      margin: 200px auto;
      margin-bottom: 170px;
      color: #4e9dce;
    }
  }
}

.clinicsDoctors {
  &-table {
    width: 95%;
    margin-bottom: 20px;
    border: 1px solid #d6d6d6;
    border-collapse: collapse;
    margin: 0 auto;
    th {
      font-weight: bold;
      padding: 5px;
      color: #4c4c4c;
      font-family: "Arial", sans-serif;
      font-size: 12px;
      border: 1px solid #dddddd;
    }
    td {
      font-family: "Arial", sans-serif;
      font-size: 12px;
      color: #26364b;
      border: 1px solid #dddddd;
      padding: 5px;
    }
  }
}

.create-acc-button {
  cursor: pointer;
  div {
    color: #0378bd;
  }
  &:hover {
    div {
      color: #04a3ff !important;
    }
  }
}
