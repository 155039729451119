.clinics-wrapper {
    margin: 0 auto;
    margin-top: 43px;
    padding: 50px;
    padding-top: 10px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-gray {
    background-color: #eef1f8;
}

.clinics-big-title {
    font-size: 24px;
    color: #49414c;
    text-align: center;
    padding-bottom: 16px;
}

.clinics-dropdown-filters {
    float: left;
}

.form-select {
    width: 20% !important;
}

.dropdown1 {
    margin-left: auto;
    margin-right: 15%;
}

.clinics-main-wrapper {
    background-color: #eef1f8;
    padding-top: 1px;
    padding-bottom: 50px;
}

.main-filters {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 908px;
    &__sorting {
        max-width: 390px;
        margin: 16px 0;
        align-self: flex-end;
        select {
            max-width: 250px;
        }
    }
}

.clinics-main-item {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 908px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    .select-your-doctor-block {
        width: 25%;
        padding: 30px 10px 0 15px;
        display: flex;
        flex-direction: column;
        h2 {
            font-size: 0.9rem;
            font-weight: bold;
            color: #49414c;
        }
        .my_doctors_list {
            flex: 1;
            max-height: 212px !important;
            overflow: hidden;
            position: relative;
            max-width: 180px;
            a {
                display: block;
                font-size: 11px;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                margin-bottom: 4px;
                color: #03a8f5;
            }
            &:after {
                position: absolute;
                bottom: 0;
                height: 100%;
                width: 100%;
                content: "";
                background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 20%);
                pointer-events: none;
            }
        }
        .expand_my_doctors {
            max-height: unset !important;
            overflow: unset !important;
            &:after {
                background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%);
            }
        }
        .show_more_clinic_doctors {
            margin-right: 25px;
            text-align: right;
            a {
                font-size: 10px;
                display: block;
                color: #37baf7;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
    }
}

.clinic-info-block {
    width: 50%;
    padding: 25px 25px 0 22px;
    .clinic_type {
        font-weight: bold;
        font-size: 14px;
        color: #49414c;
    }
    .about-clinic {
        p {
            font-size: 11px;
            margin-bottom: 10px;
        }
    }
}

.regular-text {
    font-size: 13px;
    color: #353240;
    line-height: 13px;
    &.doctor-card {
        min-width: 50%;
    }
}

.clinic-title-1 {
    font-size: 15px;
    font-weight: bold;
    color: #49414c;
    line-height: 17px;
}

.about-clinic-item {
    font-size: 13px;
    display: inline-block;
    color: #818181;
}

.clinic-title-2 {
    font-size: 14px;
    color: #49414c;
    font-weight: bold;
    line-height: 14px;
    margin-bottom: 10px;
}

.image-block {
    width: 250px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: url("./../../images/item-bg-img.png") no-repeat;
    background-size: 250px;
    box-shadow: inset 0 0 0 1000px rgba(84, 162, 210, 0.6);
}

.clinic-image-block {
    position: relative;
    .like_btn {
        margin: -10px 0 0 45px;
        &:hover {
            cursor: pointer;
        }
        z-index: 2;
        position: absolute;
        color: #898989;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #ffe554;
        display: flex;
        justify-content: center;
        svg {
            margin-top: 7px;
            font-size: 21px;
        }
    }
    .clinic-default-img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        margin: 45px 0 0 60px;
        display: block;
        z-index: 1;
        border-radius: 20px;
    }
}

.clinic-rating-block {
    margin-top: 45px;
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    text-align: center;
    padding-top: 20px;
}

.stars-block {
    margin-top: 20px;
}

.star-img {
    width: 22px;
    margin-right: 8px;
}

.star-img:hover {
    cursor: pointer;
}

.star-img:first-child {
    margin-left: 50px;
}

.reviews-count {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.clinic-description-block {
    margin-top: 17px;
    .clinic-title-2 {
        font-size: 0.9rem;
    }
    .show_more_clinic_description {
        font-size: 10px;
        color: #37baf7;
        text-align: right;
        text-decoration: underline;
        margin-top: -8px;
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}

.clinic-description-block {
    .clinic-desc-text {
        line-height: 18px;
        height: 120px;
        overflow: hidden;
        position: relative;
        &:after {
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            content: "";
            background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 25%);
            pointer-events: none;
        }
        p {
            font-family: unset !important;
            font-size: 13px !important;
            color: black !important;
        }
    }
    .all-clinic-desc {
        height: unset !important;
        overflow: unset !important;
        &:after {
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            content: "";
            background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%);
            pointer-events: none;
        }
    }
}

.pagination-clinics {
    text-align: center;
}

.pagination-clinics-btn {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
    padding-top: 7px;
    border-radius: 3px;
    margin-top: 60px;
    color: #707070;
}

.pagination-clinics-btn:hover {
    background-color: #83d6fc;
    cursor: pointer;
    color: white;
}

.pagination-clinics-btn:first-child {
    background-color: #83d6fc;
    color: white;
}

.pagination-clinics-btn:last-child {
    margin-right: 0;
    background-color: #83d6fc;
    color: white;
}

.pagination-clinics-btn:first-child:hover,
.pagination-clinics-btn:last-child:hover {
    background-color: #78c3e6;
}

.main-filters__sorting {
    label {
        margin-right: 7px;
        margin-left: 35px;
    }
    select {
        border-radius: 10px;
    }
}