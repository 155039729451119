.header {
    border-bottom: 1px solid rgb(196, 196, 196);
    padding: 12px 0 12px 0;
}

.header-wrapper {
    max-width: 940px;
    margin: 0 auto;
    display: flex;
    
}

.logo-block {
    display: flex;
}

.header-logo {
    width: 85px;
    height: 85px;
}

.header-title {
    font-size: 26px;
    font-family: Arial;
    color: #0279BD;
    margin: 25px 0 0 12px;
}

.contact-information {
    display: flex;
    margin-left: auto;
}

#dropdown-basic {
    text-decoration: none;
    margin-right: 25px;
    color: black;
    font-size: 14px;
}

.header-phone {
    margin-top: 7px;
    font-size: 14px;
}

.big-title {
    font-size: 18px;
    font-family: Arial;
    font-weight: 600;
    color: #49414C;
    text-align: center;
    padding: 0 0 0 0;
}

.big-title.vaccantions {
    text-align: start !important;
}

.small-title {
    font-size: 18px;
    font-family: Arial;
    color: #49414C;
    text-align: center;
    margin: 40px 0 8px 0;
}