@import "../../variables.scss";

.cabinet-wrapper-doc {
    max-width: 910px;
    margin: 0 auto;
    margin-top: 24px;

    .cabinet-feature-image {
        min-width: 85px;
        height: 85px;
        background: white;
        min-height: 0;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;

        * {
            font-size: 48px;
            color: $button-blue;
        }
    }

    .blue-btn {
        margin-left: 16px;
    }

    .MuiTabs-flexContainer{
        background-color:#ffff;
    }

    .MuiTab-textColorInherit.Mui-selected{
        opacity: 1;
        background-color: #eef2f8;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        outline: 0;
    }

    .MuiBox-root {
        background-color:#eef2f8;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
    }

    #simple-tabpanel-1 .MuiBox-root {
        border-top-left-radius: 24px;
    }

    .button:focus{
    outline: none;
    }
    .MuiTabs-indicator {
        background-color:#eef2f8;
      
    }
    .bottom-block {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
        padding-right: 24px;
        padding-bottom: 16px;
        .show-more-btn {
            width: 140px;
            font-size: 13px;
            margin: unset !important;
        }
        .show-more-btn:first-child {
            margin-right: 1rem !important;
        }
    }
    .my-profile {
        margin: 0 auto;
        h2 {
            margin-top: 50px;
        }
        .features-block {
            background-color: #eef2f8;
            min-height: 100px;
            padding: 32px;
            flex-wrap: wrap;
            display: flex;

            .item {
                color: black;
                display: flex;
                width: 48%;
                margin-right: 4%;
                margin-top: 30px;
                img {
                    width: 85px;
                    height: 85px;

                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                }
                div {
                    margin-left: 10px;
                    h5 {
                        font-size: 1rem;
                    }
                    p {
                        font-size: 0.7rem;
                    }
                }
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    margin-top: 0;
                }

                &:nth-of-type(2) {
                    margin-right: 0;
                }

                &:nth-of-type(4) {
                    margin-right: 0;
                }

                &:last-child {
                    margin-right: auto;
                }
            }
        }
    }
}
