@import '../../variables';

.rating-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rating-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding: 8px 0;
    font-size: 13px;
}

.rating-doctor {
    color: $blue-main;
    text-align: center;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
}

.rating-review {
    font-size: 13px;
    font-weight: bold;
    color: $green-main;
    text-align: center;
}