.filters-wrapper {
    max-width: 1050px;
    margin-left: 9%;
    margin: 0 auto;
}

.filters {
    width: 910px;
    margin: auto;
}

.extra-top-filter {
    padding-top: 32px;
}

.dropdown-filters-wrapper {
    background-color: #eef2f8;
    padding: 32px 12px;
    display: flex;
    .dropdown-filters {
        width: 100%;
        margin: 0 12px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

        .react-autosuggest__input {
            font-family: unset;
            font-size: 13px;
            padding: 8px 16px;
        }
    }

    .btn {
        margin: 0 12px;
    }
}

.list-filters {
    background-color: #eef2f8;
    padding: 32px 12px;
    display: flex;
    flex-direction: column;

    &__default-filters {
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: nowrap;
        flex: 1;

        label {
            align-self: center;
        }
        input {
            min-width: 30%;
        }
    }
    &__default-filters > * {
        margin: 0 12px 0 12px;
    }
    &__search {
        font-size: 13px !important;
        float: right;
    }
    &__search-btm {
        font-size: 13px !important;
        float: right;
        margin: 8px 0;
    }
}